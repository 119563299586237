.component-container {
  height: 100%;
}

.c2 {
  margin-top: 5.8rem;
  margin-left: -5rem;
}

h3 {
  margin: 1rem;
  font-size: 1.3rem;
}
