/* Carcarousel.css */

.carcarousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.carcarousel-inner {
  display: flex;
  transition: transform 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Custom cubic bezier for smooth sliding */
  width: 300%;
}

.carcarousel-inner,
.carcarousel-slider {
  transition: transform 0.5s ease; /* Add transition for smooth image changes */
}

.carcarousel-item {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 33.33vw;
  flex-direction: column;
  z-index: 100;
  align-items: center;
  transition: transform 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Custom cubic bezier for smooth sliding */
}

.carcarousel-button-container {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  margin-left: 0.25rem;
}

.carcarousel-button {
  font-size: 14px;
  color: white;
  background: #313639;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s ease-in-out; 

  
}

.carcarousel-button-prev:hover {
  background: red;
  color: white;
  /* background-color: #AD310B; */
  -webkit-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.carcarousel-button-next:hover {
  background: red;
  color: white;
  -webkit-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;  

}

.carcarousel-button-prev,
.carcarousel-button-next {
  font-size: 2rem;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out; /* Transition on button hover */
  color: black;
  background-color: #fff;
  width: 3rem;
  height: 3.2rem;
  text-align: center;
  box-shadow: 0 0 0.4375rem rgba(0,0,0,.15);
}

.carcarousel-button-prev:hover,
.carcarousel-button-next:hover {
  opacity: 1;
}

.carcarousel-button-prev:disabled,
.carcarousel-button-next:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.3);
}

.carcarousel-slider {
  display: flex;
}

.carcarousel-item:hover .carcarousel-button{
  background-color: #d5001c;
}

.carcarousel-item:hover{
  cursor: pointer;
}

.carcarousel-button{
  display: flex;
  align-items: center;
}

.carcarousel-button>span{
  margin-bottom: 13px;
  margin-right: 5px;
}

.carcarousel-item>button {
  /* width: 10rem; */
  padding: 0 1rem;
  height: 3rem;
  overflow: hidden;
  border: none;
}

.disabled {
  visibility: hidden !important;
}
