.disdiv {
  /* margin-left: 1rem; */
}

.discontainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.childimg > img {
  object-fit: contain;
  width: 340px;
}

.childimg:hover {
  cursor: pointer;
}

/* discover.css */

.childimg {
  position: relative;
  overflow: hidden;
}

.childimg img {
  max-width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.childimg::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Set the initial height of the sliding border */
  background-color: #d5001c; /* Border color */
  transform: scaleX(0); /* Initially, the border has no width */
  transform-origin: 0 0; /* Start the scaling from the right */
  transition: transform 0.3s ease-in-out;
}

.childimg:hover::after {
  transform: scaleX(1); /* Expand the border to the full width on hover */
}

.imgtxt {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.imgtxt {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative; /* Set relative positioning for the container */
}

.imgtxt p {
  position: absolute; /* Set absolute positioning for the paragraph */
  bottom: 0; /* Position it at the bottom */
}

/* discover.css */

.childimg {
  position: relative;
  overflow: hidden;
}

.childimg img {
  max-width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.childimg::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 4.2rem;
  height: 4px;
  background-color: #d5001c;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: transform 0.3s ease-in-out;
}

.img-container {
  position: relative;
}

.img-container p {
  margin-top: 2rem;
}

.spntxt > span {
  font-size: 2.5rem;
  /* padding-top: 5px; */
  /* margin-bottom: 2rem; */
}

.childimg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spntxt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10rem;
  margin-left: 5px;
}

.spntxt > p {
  padding-top: 1.7rem;
  padding-left: 5px;
}

.spntxt > img {
  transition: filter 0.3s ease;
}

.spntxt > img:hover {
  filter: hue-rotate(0deg);
}

.childimg:hover .spntxt{
  color: #d5001c;
}

.btext{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.spntxt>span{
  color: #d5001c;
}