/* CSS */
.search-input {
  margin-top: 5.9rem;
}

input[type="text"] {
  width: 15rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px 0 0 10px;
  outline: none;
  border: none;
  height: 4rem;
}

button.search-button {
  /* background: #007bff; */
  background: #fff;
  color: #fff;
  border: none;
  border-radius: 0 10px 10px 0;
  padding: 20.2px 15px;
  cursor: pointer;
  height: 4rem;
}

button.search-button i {
  font-size: 18px;
}

/* Adjust the styles as needed */
