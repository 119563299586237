.parent {
  display: flex;
  height: 360px;
  overflow: hidden;
  margin-bottom: 5rem;
  width: 90vw;
  position: relative;
  margin-left: 5rem;
  margin-top: 2rem;
}

.parent > div {
  display: flex;
  flex-direction: column-reverse;
}

.image-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  padding: 0;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.image-container:hover .link{
  color: red;
}

.larger {
  flex: 1.6;
}
.image-container img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay {
  color: white;
  padding: 5px;
  text-align: left;
  width: fit-content;
}

.overlay a {
  margin: 0 5px;
  padding: 5px;
  padding-bottom: 2px;
  width: fit-content;
}

.div1 {
  background-image: url("https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-shoppingtools-compare/normal/4365f64f-2c10-11e7-9f74-0019999cd470;sN;twebp/porsche-normal.webp");
}

.div2 {
  background-image: url("https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-shoppingtools-dealersearch/normal/c365840c-687b-11e7-9717-0019999cd470;sQ;twebp/porsche-normal.webp");
}

.div3 {
  background-image: url("https://files.porsche.com/filestore/image/middle-east/none/homepage-teaser-shoppingtools-pds/normal/94ab936b-9704-11e7-b591-0019999cd470;sQ;twebp/porsche-normal.webp");
}

.div4 {
  background-image: url("https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-shoppingtools-kontakt/normal/df7cfdaf-9790-11e7-b591-0019999cd470;sQ;twebp/porsche-normal.webp");
}

.link {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  text-decoration: none;
  color: white;
}

.link:hover {
  color: red;
}

.link > span {
  font-size: 2.4rem;
  text-align: center;
  padding-bottom: 18px;
  padding-right: 5px;
  color: red;
  margin-top: 9px;
}
