.footer{
    width: 90vw;
    margin-left: 2.5vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
}

/* .coll:nth-child(4){
    margin-right: 2rem;
} */

.footlink{
    list-style-type: none;
    margin: 1rem 0;
}

.footlink>a{
    color: black;
    text-decoration: none;
    font-size: 1.1rem;
    margin: 20px 0;
}

.footitems>ul{
    padding: 0;
    margin: 0;
}

.footlink>button{
    background-color: #313639;
    width: 100%;
    height: 3.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 1.3rem;
    margin-top: 3rem;
}

.sp>span{
    margin: 0 1rem;
    filter: invert(1);
}

.footlink>a>img{
    width: 45px;
    height: 45px;
    object-fit: contain;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #313639;
}

.footlink>a:nth-child(1)>img:hover{
    background-color: #3b5998;
}
.footlink>a:nth-child(2)>img:hover{
    background-color: #d5001c;
}
.footlink>a:nth-child(3)>img:hover{
    background-color: #d5001c;
}
.footlink>a:nth-child(4)>img:hover{
    background-color: #143c91;
}
.footlink>a:nth-child(5)>img:hover{
    background-color: #0077B5;
}

