/* Footer.css */

.drps {
  position: relative;
  display: flex;
  width: fit-content;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px; */
  z-index: 1;
  top: -16vw;
  left: 16px;
  width: 22vw;
}

.dropdownContent button{
    color: #d5001c;
}

.drps.show .dropdownContent {
  display: block;
}

.drps button {
  background-color: #313639;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin: 1rem;
  width: 20vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.last {
  background-color: #191f22;
  bottom: 0;
  position: relative;
}
.legaldown > p {
  color: white;
  margin-bottom: 0;
  padding-bottom: 2rem;
  padding-top: 1rem;
  margin: 0 5vw;
}
.legalup > h3 {
  color: white;
  margin-left: 5vw
}

.drps > button > span {
  transform: rotate(90deg);
  display: inline-block;
  font-size: 2rem;
}

.legalup {
  display: flex;
  flex-direction: column;
}

.btncon {
  display: flex;
  width: 60vw;
  justify-content: flex-start;
  margin-left: 4vw;
}

.drps.dis>button{
    color: grey;
}

.legallinks{
    text-decoration: underline;
    font-size: 1.01rem;
}
.legallinks:hover{
    text-decoration: none;
    color: #d5001c;
    cursor: pointer;
}

.legaldown>p{
    font-size: 1.01rem;
}