@import url(https://db.onlinewebfonts.com/c/5089476f655e8aede1cdfa0200417f33?family=AntartidaRD+Essential+W00+Bold);
* {
  padding: 0;
  margin: 0;
  transition: 0.15s all ease-in;
  user-select: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #ff0000 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ff0000;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.logo > a {
  margin-top: -71px;
  margin: 40%;
}

.logo {
  position: absolute;
  margin-top: -4.4rem;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  z-index: 12;
  margin-left: 1.7rem;
  margin-top: 11px;
  transition: transform 0.5s ease-in-out;
}
.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 4;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 4;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 4;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 4;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 4;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 4;
}

.mm{
  font-size: 1.5rem;
  padding-left: 5px;
  padding-top: 2px;
}

.ra {
  /* margin-top: 9px; */
  font-size: 3rem;
  padding-bottom: 12px;
  padding-right: 11px;
}

.carousel-caption > h2 {
  text-align: left;
  font-size: 3.7rem;
}
.carousel-caption > p {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}
.carousel-caption {
  margin-left: -8rem;
}
.carousel-caption:hover {
  color: red;
}

.carousel-control-next-icon {
  background-color: red;
  margin-right: -12rem;
}
.carousel-control-prev-icon {
  background-color: red;
  margin-left: -12rem;
}

.card-title,
.card-text {
  color: white;
  margin-left: 1rem;
}

.card-title {
  font-size: 2.6rem;
}

.card {
  border-radius: 20px;
}
.card > img {
  border-radius: 20px;
}

.g-4 {
  --bs-gutter-x: 1.1rem;
  --bs-gutter-y: 1.25rem;
}

.bottom {
  bottom: 0;
  margin-bottom: 2rem;
  position: absolute;
  display: flex;
}

.btn {
  margin-right: 1rem;
  height: 3.4rem;
  font-size: 1rem;
  border-radius: 0;
  border: none;
}

.btn:hover {
  background-color: #d5001c;
  color: white;
}

.btn:hover .exp{
  color: white;
}

.tsp {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
.tsp:hover {
  background-color: transparent;
  color: #d5001c;
  /* transition: all 0.1s ease-in-out; */
  border: 1px solid #d5001c;
}

.btn-light:hover img{
  filter: invert(1);
}


.tsp > img {
  width: 17px;
  padding-bottom: 4px;
  filter: invert(1);
}

button > a > img {
  width: 17px;
  float: right;
  padding-top: 9px;
  margin-right: 1.5rem;
}

button > a {
  text-decoration: none;
  color: black;
}
button > a:hover {
  text-decoration: none;
  color: black;
}

.txt {
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  background-color: white;
}

.sidebar {
  width: 54vw;
  position: absolute;
  left: 0;
  top: 0;
  height:100vh;
  display: none;
  justify-content: space-between;
}

.txt > button {
  width: 300px;
  text-align: left;
  height: 3.3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  border-radius: 10px;
  padding-left: 15px;
  border: none;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1.4rem;
  user-select: none;
}

.txt > button:hover {
  background-color: rgba(148, 149, 152, 0.18);
}

.images {
  background-color: #eeeff2;
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  /* pointer-events: none; */
}

.c {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  /* pointer-events: none; */
}

.images > div > img {
  width: 196px;
}

.menu {
  /* Your existing styles for the menu */

  /* Add transition properties */
  transition: all 0.7s ease-in;
}

.sidebar {
  /* Your existing styles for the sidebar */

  /* Add transition properties */
  transition: all 0.7s ease-in;
  transform: translateX(-120%); /* Start the sidebar off-screen */
  backdrop-filter: blur(8px);
  width: 100vw;
  position: fixed;
  justify-content: flex-start;
}

/* When the menu is opened, translate it off-screen to the left */
.menu.opened {
  transform: translateX(-220%);
}

/* When the sidebar is visible, bring it on-screen */
.sidebar.visible {
  transform: translateX(0%);
  transition: all 0.7s ease-in;
}

.btn-close {
  margin-left: 2rem;
  margin-top: 1rem;
  border: 2px solid grey;
  padding: 2px;
  height: 3rem;
  width: 3em;
}

/* .blur{
  filter: blur(6);
} */

.tsp{
  display: flex;
  align-items: center;
}